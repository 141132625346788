import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--font-open-sans\",\"display\":\"swap\"}],\"variableName\":\"open_sans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Dosis\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--font-dosis\",\"display\":\"swap\"}],\"variableName\":\"dosis\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-accessible-accordion/dist/fancy-example.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/swiper-bundle.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Layouts/AosAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Shared/GoTop.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/flaticon.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/fontawesome.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/style.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/responsive.css");
